import { faPlaneArrival, faPlaneDeparture } from "@fortawesome/pro-light-svg-icons";
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AirlineLogo } from "components/Shared/AirlineLogo";
import React, { useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { ConnectionHeaderView } from "WebApiClient";



interface LegProps {
    Data: ConnectionHeaderView;
    LegIndex: number;
    PlatingCarrierCode: string;
    PlatingCarrierName: string;
    ShowLegPlatingCarrier: boolean;
    ToggleFlightTimes: () => void;
    IsExpanded: boolean
}

const FareLegHeader: React.FC<LegProps> = props => {
    const [arrivalTooltipOpen, setArrivalTooltipOpen] = useState(false);
    const arrivalTooltipContent = `${props.Data?.arrival} (${props.Data?.arrivalFullName})`;
    const togglearrivalToolTip = () => setArrivalTooltipOpen(!arrivalTooltipOpen);
    const arrivalRef = useRef<HTMLSpanElement>(null);

    const [departureTooltipOpen, setDepartureToolTipOpen] = useState(false);
    const departureTooltipContent = `${props.Data?.departure} (${props.Data?.departureFullName})`;
    const toggledepartureToolTip = () => setDepartureToolTipOpen(!departureTooltipOpen);
    const departureRef = useRef<HTMLSpanElement>(null);

    return (
        <div className="segment bb-1 clickme" style={{ display: "block" }} onClick={props.ToggleFlightTimes}>
            <div className="py-2 d-flex justify-content-center align-items-center">
                <Popover target={arrivalRef} isOpen={arrivalTooltipOpen} toggle={togglearrivalToolTip}>
                    <PopoverBody>
                        {arrivalTooltipContent}
                    </PopoverBody>
                </Popover>
                <Popover target={departureRef} isOpen={departureTooltipOpen} toggle={toggledepartureToolTip}>
                    <PopoverBody>
                        {departureTooltipContent}
                    </PopoverBody>
                </Popover>
                <div
                    className="segment__departure_airport"
                >
                    <span onMouseEnter={() => setDepartureToolTipOpen(true)}
                    onMouseLeave={() => setDepartureToolTipOpen(false)}
                    onTouchStart={(e) => { e.stopPropagation(); setDepartureToolTipOpen(true) }}
                    onTouchEnd={() => setDepartureToolTipOpen(false)} ref={departureRef}>{props.Data?.departure}</span>
                </div>
                <div className="segment__departure_time text-nowrap">
                    {props.Data?.departureDisplayTime}
                    <div className="" style={{ fontSize: "0.6em" }}>
                        <span>
                            <FontAwesomeIcon icon={faPlaneDeparture} /> {' '} {props.Data?.departureDate}</span>
                    </div>
                </div>
                <div className="segment__stops d-flex justify-content-center">
                    <div className="segment__stops_duration w-75">
                        <div className="segment__stops_traveltime">
                            {props.Data?.legTravelTime!.addHours}h {props.Data?.legTravelTime!.minutes}m
                        </div>
                        <StopsIndicator Data={props.Data?.travelStopps} />
                    </div>
                </div>
                <div className="segment__arrival_time text-nowrap justify-content-center">
                    {props.Data?.arrivalDisplayTime}
                    <span className="nextday"><sup>{props.Data?.dayIndicator}</sup></span>
                    <div className="" style={{ fontSize: "0.6em" }}>
                        <span>
                            <FontAwesomeIcon icon={faPlaneArrival} /> {' '} {props.Data?.arrivalDate}</span>
                    </div>
                </div>
                <div className="segment__arrival_airport mr-2 pulse">
                    <span onMouseEnter={() => setArrivalTooltipOpen(true)}
                    onMouseLeave={() => setArrivalTooltipOpen(false)}
                    onTouchStart={(e) => { e.stopPropagation(); setArrivalTooltipOpen(true) }}
                    onTouchEnd={() => setArrivalTooltipOpen(false)} ref={arrivalRef}>{props.Data?.arrival}</span>
                </div>
                {
                    props.ShowLegPlatingCarrier &&
                    <AirlineLogo
                        PlatingCarrier={props.PlatingCarrierCode}
                        PlatingCarrierName={props.PlatingCarrierName}
                        IsAlliance={false}
                    />
                }

                <div className="float-end ml-2">
                    <FontAwesomeIcon icon={props.IsExpanded ? faAngleDoubleUp : faAngleDoubleDown} />
                </div>
            </div>
        </div>
    );
};

interface StopsIndicatorProps {
    Data: number;
}
export const StopsIndicator: React.FC<StopsIndicatorProps> = props => {
    let className: string = "";
    let label: string = "";
    const amount = props.Data;
    switch (amount) {
        case 0:
            className = "segment__stops_direct";
            label = "Nonstop";
            break;
        case 1:
            className = "segment__stops_1";
            label = "1 Stop";
            break;
        case 2:
            className = "segment__stops_2plus";
            label = "2 Stops";
            break;
        default:
            className = "segment__stops_2plus";
            label = `${amount} Stops`;
            break;
    }
    return <React.Fragment>
        <div className="segment__stops_travelstops">
            <div className={`${className} segment__stops_totalstops`}>{label}</div>
        </div>

    </React.Fragment>



}
export default FareLegHeader;