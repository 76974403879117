import { Adress, BookingAddressBehavior } from "WebApiClient";



export interface BookAddressValidationResult {
    Valid: boolean;
    Title: boolean;
    FirstName: boolean;
    LastName: boolean;
    EmailAddress: boolean;
    PhoneNumber: boolean;
    Street: boolean;
    Zip: boolean;
    City: boolean;
    Country: boolean;

}

export const DefaultBookAddressValidationResult: BookAddressValidationResult = {
    Valid: false,
    Title: false,
    FirstName: false,
    LastName: false,
    EmailAddress: true,
    PhoneNumber: true,
    Street: false,
    Zip: false,
    City: false,
    Country: false
}




export function AddressValid(data: Adress | undefined, behavior: BookingAddressBehavior): BookAddressValidationResult {
    let addressValid = true;


    const canInputAdress = behavior !== BookingAddressBehavior.UseBranchAdress;

    const titleValid: boolean = canInputAdress ? (data?.title !== null && data?.title !== "") : true;
    if (!titleValid) {
        addressValid = false;
    }

    const firstNameValid: boolean = canInputAdress ? (data?.firstName !== null && data?.firstName !== "") : true;
    if (!firstNameValid) {
        addressValid = false;
    }
    const lastNameValid: boolean = canInputAdress ? (data?.lastName !== null && data?.lastName !== "") : true;
    if (!lastNameValid) {
        addressValid = false;
    }

    const streetValid: boolean = canInputAdress ? (data?.street !== null && data?.street !== "") : true;
    if (!streetValid) {
        addressValid = false;

    }

    const zipValid: boolean = canInputAdress ? (data?.zip !== null && data?.zip !== "") : true;
    if (!zipValid) {
        addressValid = false;

    }

    const cityValid: boolean = canInputAdress ? (data?.city !== null && data?.city !== "") : true;
    if (!cityValid) {
        addressValid = false;

    }

    const countryValid: boolean = canInputAdress ? (data?.country !== null && data?.country !== "") : true;
    if (!countryValid) {
        addressValid = false;

    }

    const result: BookAddressValidationResult = {
        Valid: addressValid,
        Title: titleValid,
        FirstName: firstNameValid,
        LastName: lastNameValid,
        EmailAddress: true,
        PhoneNumber: true,
        Street: streetValid,
        Zip: zipValid,
        City: cityValid,
        Country: countryValid
    }
    return result;
}
export function InvoiceValid(data: Adress | undefined): BookAddressValidationResult {
    let addressValid = true;




    const titleValid: boolean = data ? (data.title !== null && data.title !== "") : true;
    if (!titleValid) {
        addressValid = false;
    }

    const firstNameValid: boolean = data ? (data.firstName !== null && data.firstName !== "") : true;
    if (!firstNameValid) {
        addressValid = false;
    }
    const lastNameValid: boolean = data ? (data.lastName !== null && data.lastName !== "") : true;
    if (!lastNameValid) {
        addressValid = false;
    }

    const emailAddressValid: boolean = data ? (data?.emailAddress !== null && data?.emailAddress !== "") : true;
    if (!emailAddressValid) {
        addressValid = true;
    }

    const phoneNumberValid: boolean = data ? (data?.phoneNumber !== null && data?.phoneNumber !== "") : true;
    if (!phoneNumberValid) {
        addressValid = true;
    }

    const streetValid: boolean = data ? (data.street !== null && data.street !== "") : true;
    if (!streetValid) {
        addressValid = false;

    }

    const zipValid: boolean = data ? (data.zip !== null && data.zip !== "") : true;
    if (!zipValid) {
        addressValid = false;

    }

    const cityValid: boolean = data ? (data.city !== null && data.city !== "") : true;
    if (!cityValid) {
        addressValid = false;

    }

    const countryValid: boolean = data ? (data.country !== null && data.country !== "") : true;
    if (!countryValid) {
        addressValid = false;

    }

    const result: BookAddressValidationResult = {
        Valid: addressValid,
        Title: titleValid,
        FirstName: firstNameValid,
        LastName: lastNameValid,
        EmailAddress: emailAddressValid,
        PhoneNumber: phoneNumberValid,
        Street: streetValid,
        Zip: zipValid,
        City: cityValid,
        Country: countryValid
    }
    return result;
}


