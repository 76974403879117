import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import Fare from "components/Fare/Fare";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { AvailableFareModel } from "WebApiClient";

export interface FareBookingOptions {
    Fare: AvailableFareModel;
    SelectedConnections: number[];
    OnSelectConnection: (legIndex: number, connectionIndex: number) => void
    Icon: JSX.Element;
    Passengers: AssignablePassengerOption[];
    AddedServices: ServiceAddObj[]
}


    const FareBookingSelectedFlight: React.FC<FareBookingOptions> = props => {

    const EnableCalcDebugger = useSelector((state: State) => state.Session.FrontendSettings.enableCalculationDeugger);
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">

                    <div className="card mb-4">
                        <div className="card-header card-header-primary clearfix">
                            <span className="float-start">{props.Icon} SELECT Connection</span>
                        </div>
                        <div className="card-body card-body-primary py-0 py-sm-1 px-md-2 py-lg-3 px-0 px-sm-1 px-md-2 px-lg-3">
                            <div className="fare__selector_container mt-1 mt-md-3">
                                <div className="fare__selector">
                                    <React.Fragment>
                                        <Fare
                                            key={"fare" + props.Fare.fareIndex}
                                            FareData={props.Fare}
                                            ShowAllLegsActive={true}
                                            ShowAllFlightTimesActive={true}
                                            ShowAllCalculationActive={false}
                                            IsFareBooking={true}
                                            isActivate={false}
                                            SelectedConnections={props.SelectedConnections}
                                            SelectConnection={props.OnSelectConnection}
                                            BookPassengers={props.Passengers}
                                            ShowCalcDebugger={EnableCalcDebugger}
                                            AddedServices={props.AddedServices}
                                            TotalFare={[]}
                                        />
                                    </React.Fragment>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FareBookingSelectedFlight;
