import React, { useEffect } from "react";
import { AvailableFareConnectionModel, AvailableFareModel } from "WebApiClient";
import FareLegConnectionTableRow from "./Fare.Leg.ConTbl.Row";
import _ from "lodash";

interface ConnectionTableProps {
  Data: AvailableFareConnectionModel[];
  SelectedConnection: any;
  GetSelectedConnectionIndexByLegIndex: any;
  LegIndex: number;
  SelectConnection: (legIndex: number, connectionIndex: number) => void;
  IsWebFare: boolean;
  ApplicableConnections: number[] | undefined;
  Fare: AvailableFareModel;
  setStatus: (status: boolean) => void;
  headerOffVisible: boolean;
  upscaleLength: number
}

const FareLegConnectionTable: React.FC<ConnectionTableProps> = (props) => {
  function HasAllotment() {
    let result = false;
    props.Data.forEach((con) => {
      con.segments.forEach((seg) => {
        if (seg.allotmentInformation) {
          console.log(seg.allotmentInformation);
          result = true;
        }
      });
    });
    return result;
  }
  // Function to check equality for all objects in the array
  const areAllIncludedServicesEqual = (
    dataArray: any,
    stringToSearch: string
  ) => {
    const firstObject = dataArray[0];
    if (dataArray.length < 2) {
      const segments1 = _.get(firstObject, "segments", []);
      for (let j = 0; j < segments1.length; j++) {
        const singleCellDoubleBaggage = areIncludedServicesEqual(
          segments1[j],
          segments1[j + 1],
          stringToSearch
        );
        if (singleCellDoubleBaggage) {
          return true;
        }
      }
    }
    if (dataArray.length > 1) {
      for (let i = 1; i < dataArray.length; i++) {
        const currentObject = dataArray[i];

        // Get the segments array from each object
        const segments1 = _.get(firstObject, "segments", []);
        const segments2 = _.get(currentObject, "segments", []);

        // Check if the lengths of segments arrays are equal
        if (segments1.length !== segments2.length) {
          return false;
        }

        // Check the equality of includedServices for each segment
        for (let j = 0; j < segments1.length; j++) {
          const includedServicesEqual = areIncludedServicesEqual(
            segments1[j],
            segments2[j],
            stringToSearch
          );
          if (!includedServicesEqual) {
            return false;
          }
        }
      }
      return true;
    }
  };
  // Function to check if the arrays of includedServices are equal
  const areIncludedServicesEqual = (
    obj1: any,
    obj2: any,
    searchString: string
  ) => {
    const services1 = _.get(obj1, searchString, []);
    const services2 = _.get(obj2, searchString, []);
    if (searchString == "cabinClassName" && services1 !== services2) {
      return false;
    }
    if (
      (typeof services1 !== "string" || typeof services2 !== "string") &&
      (services1.length == 0 || services2.length == 0)
    ) {
      return true;
    }
    if (
      (typeof services1 === "string" || typeof services2 === "string") &&
      (services1 == "" || services2 == "")
    ) {
      return true;
    }
    return _.isEqual(services1, services2);
  };

  const cabinIsPresentAll = areAllIncludedServicesEqual(
    props.Data,
    "cabinClassName"
  );

  useEffect(() => {
    props.setStatus(true);
  }, []);

  return (
    <React.Fragment>
      {
        props.headerOffVisible == false ? <div className="upSellCard">
          <FareLegConnectionTableRow
            Data={props.Data[props.SelectedConnection]!}
            SelectedConnection={props.SelectedConnection}
            GetSelectedConnectionIndexByLegIndex={
              props.GetSelectedConnectionIndexByLegIndex
            }
            connection={props.Data[props.SelectedConnection]}
            LegIndex={props.LegIndex}
            ConnectionIndex={props.Data[props.SelectedConnection]?.index}
            key={"FareLegConnectionTableRows1" + props.Data[props.SelectedConnection]?.index}
            SelectConnection={props.SelectConnection}
            IsWebFare={props.IsWebFare}
            connectionAppliesToFilter={
              props.ApplicableConnections
                ? props.ApplicableConnections.includes(props.Data[props.SelectedConnection]?.index)
                : true
            }
            Fare={props.Fare}
            cabinIsPresentAll={cabinIsPresentAll}
            headerOffVisible={props.headerOffVisible}
          />
        </div> :
          <div
            style={{ overflow: "auto" }}
            className="multi-collapse segment__moreFlightTimes"
          >
            <table className="table table-sm table-bordered table-responsive-xl">

              <thead className="vertical-table">
                <tr>
                  <th style={{ width: "1%" }}></th>
                  <th>Routing</th>
                  {/* {!cabinIsPresentAll && <th>Cabin</th>} */}
                  <th>Flight</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>RBD</th>
                  <th>Baggage</th>
                  <th>Incl.Services</th>

                  {HasAllotment() && <th>A</th>}
                  {/*
                                <th><span className="d-md-inline">Details</span></th>
                                <th></th> */}
                </tr>
              </thead>

              <tbody>
                {
                  props.Data.map((connection, index) => (
                    <FareLegConnectionTableRow
                      Data={connection!}
                      SelectedConnection={props.SelectedConnection}
                      GetSelectedConnectionIndexByLegIndex={
                        props.GetSelectedConnectionIndexByLegIndex
                      }
                      connection={props.Data}
                      LegIndex={props.LegIndex}
                      ConnectionIndex={connection.index}
                      key={"FareLegConnectionTableRows1" + index}
                      SelectConnection={props.SelectConnection}
                      IsWebFare={props.IsWebFare}
                      connectionAppliesToFilter={
                        props.ApplicableConnections
                          ? props.ApplicableConnections.includes(connection.index)
                          : true
                      }
                      Fare={props.Fare}
                      cabinIsPresentAll={cabinIsPresentAll}
                      headerOffVisible={props.headerOffVisible}
                    />
                  ))
                }
              </tbody>
            </table>
          </div>
      }

    </React.Fragment>
  );
};

export default FareLegConnectionTable;
