import {
  faBars,
  faDollarSign,
  faFileImport,
  faSlidersH,
  faSync,
  faTable,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import {
  KeyBoardModal,
  KeyBoardShortcutDescription,
} from "components/Shared/KeyBoardModal";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import { useBootstrap5MediaQuery } from "hooks/useMediaQuery";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeAgo from "react-timeago";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { State } from "rootExports/rootReducer";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import {
  AgentPlusBookingManagerGlobalFeature,
  BookingItemModel,
  BookingManagementOverviewApiResponse,
  BookingMangerFilterRequestData,
} from "WebApiClient";
import { DefaultBookingManagerFilter } from "../types/BookingManagerTypes";
import { BookingManagerFilterModal } from "./BookingManager.Filter";
import LastSentInfo from "./BookingManager.LastSentInfo";
import BookingManagerQuickFilter from "./BookingManager.QuickFilter";
import BookingManagerTable from "./BookingManager.Table";
import {
  BookingManagerAmountInfo,
  BookingManagerTablePaging,
} from "./BookingManager.Table.Paging";

import DownloadSalesReportModal from "./DownloadSalesReportModal";
import ImportBookingModal from "./ImportBookingModal";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { toNumber } from "lodash";

const BookingManager: React.FC<{ CurrentFilter: BookingMangerFilterRequestData; OnFilterUpdate: (data: BookingMangerFilterRequestData) => void; }> = ({ CurrentFilter, OnFilterUpdate }) => {
  const bookingClient = useBookingClient();
  const [bookinsResponse, setBookingsResponse] = useState<
    BookingManagementOverviewApiResponse | undefined
  >(undefined);
  const [sideBarOpenState, setSidebarOpenState] = useState(false);
  const [IsFetching, SetIsFetching] = useState(true);
  const [Error, SetError] = useState(false);
  const [buttonDropdownOpen, setButtonDropdownOpen] = useState(false);
  const [cardView, setCardView] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [exportSalesModalOpen, setExportSalesModalOpen] = useState(false);
  const [LastUpdated, SetLastUpdated] = useState(new Date());
  const bookings: BookingItemModel[] = bookinsResponse?.result?.bookings ?? [];
  const currentOffset = CurrentFilter.itemsOffset;
  const pageLength = CurrentFilter.maxItems;
  const currentBookings = bookinsResponse?.result?.bookings?.length ?? 0;
  const LastSentFilter =
    bookinsResponse?.result?.appliedFilter ?? DefaultBookingManagerFilter;
  const [ShoppingCartView, SetShoppingCartView] = useState(false);
  const TotalShoppingCarts = bookinsResponse?.result?.totalShoppingCarts ?? 0;
  const PageAmount = bookinsResponse?.result?.pageAmount ?? 0;
  const [hideTestBookings, setHideTestBookings] = useState(true);
  const TestBookingsAmount =
    bookinsResponse?.result?.bookings?.filter(
      (e) => e.latestVersion.generalInfo.filekey.toLowerCase() === "testkey"
    ).length ?? 0;
  const allowedGlobalActions = useSelector(
    (state: State) =>
      state.Session.FrontendSettings.allowedBookingManagerGlobalFeatures
  );
  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None'
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );
  let settingArr = otherSettings.split("\n");
  var autoRefreshValue = settingArr[4] == undefined ? 0 : settingArr[4];
  var noTestBooking = settingArr[7] == undefined ? false : settingArr[7].split("#").includes("NOTESTBOOKINGS");
  const breakpoint = useBootstrap5MediaQuery();
  const dispatch = useDispatch();
  const [testBooking, setTestBooking] = useState<boolean>(noTestBooking);
  const [autoRefresh, setautoRefresh] = React.useState(autoRefreshValue.toString());
  const [keyBoardModalOpen, setKeyboardModalOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  function ToggleKeyBoardModal() {
    setKeyboardModalOpen(!keyBoardModalOpen);
  }

  useEffect(() => {
    GetBookingsWithCurrentFilter();
  }, []);

  useEffect(() => {
    if (["SM", "XS", "MD"].includes(breakpoint)) {
      setCardView(true);
    } else {
      setCardView(false);
    }
  }, [breakpoint]);

  useEffect(() => {
    if (mounted) {
      if (toNumber(autoRefresh) !== 0) {
        const times = toNumber(autoRefresh) * 60000;
        const interval = setInterval(GetBookingsWithCurrentFilter, times);
        return () => clearInterval(interval);
      }
    } else {
      setMounted(true);
    }
  }, [toNumber(autoRefresh), GetBookingsWithCurrentFilter]);

  function OnUpdateBooking(data: BookingItemModel, bookingID: string) {
    if (bookinsResponse?.result?.bookings) {
      let bookingsCopy = [...bookings];

      const iOf = bookingsCopy.findIndex(
        (e) => e.latestVersion.bookingIdentifier.bookingId == bookingID
      );
      if (iOf !== -1) {
        bookingsCopy[iOf] = data;
        const reponseCopy: BookingManagementOverviewApiResponse = {
          ...bookinsResponse,
          result: { ...bookinsResponse.result, bookings: bookingsCopy },
        };
        setBookingsResponse(reponseCopy);
      }
    }
  }
  function SetCurrentFilter(filter: BookingMangerFilterRequestData) {
    OnFilterUpdate(filter);
  }
  function ToggleFilter() {
    setSidebarOpenState(!sideBarOpenState);
  }
  function ToggleImport() {
    setImportOpen(!importOpen);
  }
  function ToggleExportSalesModal() {
    setExportSalesModalOpen(!exportSalesModalOpen);
  }
  function SetSideBarOpen(open: boolean) {
    setSidebarOpenState(open);
  }

  function GetBookingsWithSetFilter(filter: BookingMangerFilterRequestData) {
    SetCurrentFilter(filter);
    GetBookings(filter);
  }
  function GetBookingsWithCurrentFilter() {
    GetBookings(CurrentFilter);
  }

  function GetBookings(filter: BookingMangerFilterRequestData) {
    SetIsFetching(true);
    bookingClient
      .getBookings(filter, null)
      .then((response) => {
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));

        setBookingsResponse(response);
        const result = response.result;
        if (result) {
          SetError(false);
        } else {
          SetError(true);
        }
      })
      .catch(() => {
        SetError(true);
      })
      .finally(() => {
        SetIsFetching(false);
        AutoFocusPage();
      });
  }
  function ToggleShoppingCartview() {
    SetShoppingCartView(!ShoppingCartView);
  }
  function ToggleCardView() {
    setCardView(!cardView);
  }
  function UpdateFilter(filter: BookingMangerFilterRequestData) {
    SetCurrentFilter(filter);
  }

  function AutoFocusPage() {
    const x = document.getElementById("screen-query");
    if (x) {
      x.focus();
    }
  }
  const handleChange = (event: SelectChangeEvent) => {
    setMounted(false);
    setautoRefresh(event.target.value as string);
  };

  const isFilterApplied = () => {
    const Data = LastSentFilter;
    const filtersApplied = (
      (Data.branchGroup && Data.branchGroup.length > 0) ||
      (Data.branch && Data.branch.length > 0) ||
      (Data.responsibleAgent && Data.responsibleAgent.length > 0) ||
      (Data.freeField && Data.freeField.length > 0) ||
      (Data.pcc && Data.pcc.length > 0) ||
      (Data.recordLocator && Data.recordLocator.length > 0) ||
      (Data.airlineCodes && Data.airlineCodes.length > 0) ||
      !!Data.bookingDateFrom ||
      !!Data.bookingDateTo ||
      !!Data.firstDepartureDate ||
      !!Data.firstDepartureDateTo ||
      !!Data.lastArrvialDate ||
      !!Data.lastArrvialDateTo ||
      (Data.gds && Data.gds.length > 0) ||
      (Data.mainTravellerLastName && Data.mainTravellerLastName.length > 0) ||
      (Data.origin && Data.origin.length > 0) ||
      (Data.destination && Data.destination.length > 0) ||
      (Data.statusCode && Data.statusCode.length > 0) ||
      !!Data.ticketTimeLimitFrom ||
      !!Data.ticketTimeLimitTo ||
      !!Data.ticketingDateFrom ||
      !!Data.ticketingDateTo ||
      (Data.transactionID && Data.transactionID.length > 0) ||
      (Data.shoppingCartID && Data.shoppingCartID.length > 0) ||
      (Data.bookingItemID && Data.bookingItemID.length > 0) ||
      (Data.externalReference && Data.externalReference.length > 0) ||
      (Data.distributionChannel && Data.distributionChannel.length > 0) ||
      (Data.web && Data.web.length > 0)
    );

    return filtersApplied ? true : false;
  };

  return (
    <React.Fragment>
      <BookingManagerFilterModal
        OnUpdate={UpdateFilter}
        OnSubmit={GetBookingsWithCurrentFilter}
        FilterOpen={sideBarOpenState}
        Toggle={ToggleFilter}
        Filter={CurrentFilter}
      />

      {/* <div className="container content-wrapper" id="screen-query"> */}
      <div>
        <div className="row">
          <div className="col-12 mb-1">
            <div className="card">
              {/* <div className="card-header card-header-primary">Filter bookings</div> */}
              <div className="card-body card-body-primary">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="d-grid">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          SetSideBarOpen(true);
                        }}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faSlidersH} rotation={90} /> Open
                        filter{" "}
                      </button>
                    </div>
                  </div>

                  <div className="col-12">
                    <BookingManagerQuickFilter
                      Filter={LastSentFilter}
                      OnSubmit={GetBookingsWithSetFilter}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header card-header-primary">
                <span className="float-end">
                  <span className="">
                    <span className="mr-2 text-muted">Last updated: <TimeAgo date={LastUpdated} /> <span>{` `}</span></span>
                    <Button color="primary" size="sm" disabled={IsFetching} onClick={GetBookingsWithCurrentFilter} className="float-end"><i className={`fad fa-sync ${IsFetching ? "fa-spin" : ""}`}></i> Refresh</Button>
                  </span>
                </span>
              </div> */}
              <div className="card-body card-body-secondary">
                {Error && (
                  <ErrorDisplay data={bookinsResponse?.responseMetaData} />
                )}
                <div className="row">
                  <div className="col-12">
                    <LastSentInfo
                      SetFilterOpen={SetSideBarOpen}
                      LastSentFilter={LastSentFilter}
                      OnSubmit={GetBookingsWithSetFilter}
                    />
                  </div>
                  <div className="col-12 mb-1">
                    <div className="row">
                      <div className="btn-toolbar col-auto col-lg-8">
                        <ButtonDropdown
                          toggle={() => {
                            setButtonDropdownOpen(!buttonDropdownOpen);
                          }}
                          isOpen={buttonDropdownOpen}
                          className="me-2"
                          size="sm"
                        >
                          <DropdownToggle caret size="sm" color="primary">
                            Actions
                          </DropdownToggle>
                          <DropdownMenu>
                            {allowedGlobalActions.includes(
                              AgentPlusBookingManagerGlobalFeature.ImportBooking
                            ) && (
                                <DropdownItem onClick={ToggleImport}>
                                  <FontAwesomeIcon icon={faFileImport} /> Import
                                </DropdownItem>
                              )}
                            {allowedGlobalActions.includes(
                              AgentPlusBookingManagerGlobalFeature.DownloadSalesReport
                            ) && (
                                <DropdownItem onClick={ToggleExportSalesModal}>
                                  <FontAwesomeIcon icon={faDollarSign} /> Sales
                                  report
                                </DropdownItem>
                              )}
                          </DropdownMenu>
                        </ButtonDropdown>
                        <div className="me-2">
                          <button
                            className="btn btn-primary btnStyle2"
                            onClick={GetBookingsWithCurrentFilter}
                          >
                            <FontAwesomeIcon icon={faSync} />
                          </button>
                          <FormControl
                            variant="outlined"
                            className="autoRefresh"
                            sx={{ minWidth: 120 }}
                          >
                            <Select
                              placeholder="Auto Refresh"
                              className="autoFous"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={autoRefresh}
                              label="Auto Refresh"
                              onChange={handleChange}
                            >
                              <MenuItem value={0}>Never</MenuItem>
                              <MenuItem value={1}>1 Minute</MenuItem>
                              <MenuItem value={5}>5 Minute</MenuItem>
                              <MenuItem value={10}>10 Minute</MenuItem>
                              <MenuItem value={30}>30 Minute</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div
                          className="btn-group me-2"
                          onClick={ToggleCardView}
                        >
                          <input
                            onChange={() => { }}
                            type="checkbox"
                            className="btn-check"
                            checked={!cardView}
                          />
                          <label className="btn btn-outline-primary">
                            <FontAwesomeIcon icon={faTable} />
                          </label>
                          <input
                            onChange={() => { }}
                            type="checkbox"
                            className="btn-check"
                            checked={cardView}
                          />
                          <label className="btn btn-outline-primary">
                            <FontAwesomeIcon icon={faBars} />
                          </label>
                        </div>

                        <div
                          className="form-check form-check-inline"
                          onClick={ToggleShoppingCartview}
                        >
                          <input
                            className="form-check-input"
                            checked={ShoppingCartView}
                            onChange={() => { }}
                            type="checkbox"
                          />
                          <label className="form-check-label">
                            Group by Cart
                          </label>
                        </div>
                        {!testBooking && (
                          <div
                            className="form-check form-check-inline"
                            onClick={() => {
                              setHideTestBookings(!hideTestBookings);
                            }}
                          >
                            <input
                              className="form-check-input"
                              checked={hideTestBookings}
                              onChange={() => { }}
                              type="checkbox"
                            />
                            <label className="form-check-label">
                              Hide test bookings ({TestBookingsAmount})
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="col-auto col-lg-4 d-flex justify-content-end align-items-center">
                        <BookingManagerTablePaging
                          OnRefresh={GetBookingsWithCurrentFilter}
                          CurrentOffset={currentOffset}
                          IsFetching={IsFetching}
                          LastSentFilter={LastSentFilter}
                          OnSubmit={GetBookingsWithSetFilter}
                          PageAmount={PageAmount}
                          PageLength={pageLength}
                          TotalBookings={TotalShoppingCarts}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 mb-1">
                    <div className="row">
                      <div className="col-auto">
                        <BookingManagerTablePaging OnRefresh={GetBookingsWithCurrentFilter} CurrentOffset={currentOffset} IsFetching={IsFetching} LastSentFilter={LastSentFilter} OnSubmit={GetBookingsWithSetFilter} PageAmount={PageAmount} PageLength={pageLength} TotalBookings={TotalShoppingCarts} />
                      </div>
                    </div>
                  </div> */}
                  {bookings.length === 0 && !IsFetching ? (
                    <div className="col-12">
                      <h2>No bookings found.</h2>
                    </div>
                  ) : (
                    <Fragment>
                      <div className="col-12">
                        <BookingManagerTable
                          IsFetching={IsFetching}
                          UpdateBooking={OnUpdateBooking}
                          GroupByShoppingCart={ShoppingCartView}
                          PageAmount={PageAmount}
                          OnSubmit={GetBookingsWithSetFilter}
                          filter={CurrentFilter}
                          Bookings={bookings}
                          HideTestBookings={hideTestBookings}
                          autoRefresh={autoRefresh}
                          cardView={cardView}
                          isFilterApplied={isFilterApplied()}
                        />
                      </div>
                    </Fragment>
                  )}
                  <div className="col-12">
                    <p className="">
                      <BookingManagerAmountInfo
                        CurrentBookings={currentBookings}
                        CurrentOffset={currentOffset}
                        TotalBookings={TotalShoppingCarts}
                      />{" "}
                      | Last updated: <TimeAgo date={LastUpdated} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Fab></Fab> */}
      </div>
      <KeyBoardModal
        open={keyBoardModalOpen}
        toggle={ToggleKeyBoardModal}
        Shortcuts={shortcuts}
      />
      <ImportBookingModal
        IsOpen={importOpen}
        Toggle={ToggleImport}
        refreshTable={GetBookingsWithCurrentFilter}
      />
      <DownloadSalesReportModal
        IsOpen={exportSalesModalOpen}
        Toggle={ToggleExportSalesModal}
      />

      {/* <BookingMangerTableRowConfirmCancelModal Booking={props.Booking} OnUpdate={props.UpdateBooking} Toggle={ToggleCancelModal} IsOpen={cancelModalOpen} />
      <BookingMangerTableRowConfirmCancelTicketModal Booking={props.Booking} OnUpdate={props.UpdateBooking} Toggle={ToggleCancelTicketModal} IsOpen={cancelTicketModalOpen} />
      <ConfirmTicketModal Booking={props.Booking} OnUpdate={props.UpdateBooking} Toggle={ToggleTicketModal} IsOpen={ticketModalOpen} />
      <BookingMangerTableRowReshopModal Booking={props.Booking} Toggle={ToggleReshopModal} IsOpen={reshopModalOpen} />
      <BookingMangerManageServicesModal Booking={props.Booking} Toggle={ToggleServiceModal} IsOpen={serviceModalOpen} /> */}

      {/* <BookingMangerManageElementsModal Booking={props.Booking} Toggle={ToggleElementsModal} IsOpen={elementsModalOpen} />
      <BookingManagerManageNotificationModal Booking={props.Booking} OnUpdate={props.UpdateBooking} Toggle={ToggleNotificationsModal} IsOpen={notificationsModalOpen} />
      <BookingDetailsModal Booking={props.Booking} OnUpdate={props.UpdateBooking} Toggle={ToggleDetailsModal} IsOpen={detailsModalOpen} />
      <BookingManagerErrorModal Close={() => { setSyncResponse(undefined); setSyncErrorOccured(false) }} IsOpen={syncErrorOccured} data={syncResponse?.responseMetaData} /> */}
    </React.Fragment>
  );
};
const shortcuts: KeyBoardShortcutDescription[] = [
  {
    Description: "Open Filter",
    Key: "F",
  },
];
export default BookingManager;
