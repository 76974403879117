
import { faAddressBook, faCalendarDay, faCommentDots, faCommentsDollar, faCopyright, faFileInvoiceDollar, faInfoCircle, faInfoSquare, faLocation, faPercentage, faPhone, faPlane, faSackDollar, faShoppingBasket, faStar, faText, faTicket, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { TabbedContent, TabbedContentProps } from "components/Shared/TabbedContent";
import React, { useState } from "react";
import { GetPnrResult } from '../../WebApiClient';
import { PnrContactsCard } from "./Pnr.ContactsCard";
import { PnrEnhancedRemarksCard } from "./Pnr.EnhancedRemarksCard";
import { PnrFreeTextCard } from "./Pnr.FreeTextCard";
import { PnrGeneralCard } from "./Pnr.GeneralCard";
import { PnrInfosCard } from "./Pnr.InfosCard";
import { PnrLocationsCard } from "./Pnr.LocationsCard";
import { PnrOsisCard } from "./Pnr.OsisCard";
import { PnrPassengersCard } from "./Pnr.PassengersCard";
import { PnrResponsePaymentCard } from "./Pnr.PaymentsCard";
import { PnrPhonesCard } from "./Pnr.PhonesCard";
import { PnrSegmentsCard } from "./Pnr.SegmentsCard";
import { PnrSksCard } from "./Pnr.SksCard";
import { PnrSpecialDiscountsCard } from "./Pnr.SpecialDiscountsCard";
import { PnrSSRsCard } from "./Pnr.SSRsCard";
import { PnrStoredFaresCard } from "./Pnr.StoredFaresCard";
import { PnrStoredServicesCard } from "./Pnr.StoredServicesCard";
import { PnrTicketedServiceCard } from "./Pnr.TicketedServicesCard";
import { PnrTicketInfoCard } from "./Pnr.TicketInfosCard";
import { PnrTicketingDatesCard } from "./Pnr.TicketingDatesCard";
import { PnrServicesCard } from "./PnrServicesCard";
export const SpecifiedBooleanDisplay: React.FC<{ value: boolean | undefined }> = ({ value }) => {
  return <span>{(value !== undefined && value !== null) ? value ? "Yes" : "No" : "Unkown"}</span>
}
export const SpecifiedNumberDisplay: React.FC<{ value: number | undefined }> = ({ value }) => {
  return <span>{(value !== undefined && value !== null) ? value.toString() : "Unkown"}</span>
}
export const BookingPnrCardData: React.FC<{ data: GetPnrResult }> = ({ data }) => {
  const defaultCol = "col-auto mb-2"
  const {
    contacts,
    enhancedRemarks, freeTextResult, infos, locations, osiElements, passengers, payments, phones,
    segments, services, skElements,
    specialDiscounts, ssrElements, storedFares,
    storedServices, ticketInfo, ticketedSevices, ticketingDates, generalInfos } = data;

  const TabContent: TabbedContentProps = {
    EnableJump: true,
    Tabs: [
      {
        Component: <PnrGeneralCard data={generalInfos} />,
        Title: "General",
        Icon: faInfoCircle,
        AmountIndicator: undefined
      },
      ...enhancedRemarks.length > 0 ? [{
        Component: <PnrEnhancedRemarksCard data={enhancedRemarks} />,
        Title: "Remarks",
        Icon: faText,
        AmountIndicator: enhancedRemarks.length
      },] : [],
      ...passengers.length > 0 ? [{
        Component: <PnrPassengersCard data={passengers} segments={segments} />,
        Title: "Passengers",
        Icon: faUsers,
        AmountIndicator: passengers.length
      },] : [],
      ...segments.length > 0 ? [{
        Component: <PnrSegmentsCard data={segments} />,
        Title: "Segments",
        Icon: faPlane,
        AmountIndicator: segments.length
      },] : [],
      ...locations.length > 0 ? [{
        Component: <PnrLocationsCard data={locations} />,
        Title: "Locations",
        Icon: faLocation,
        AmountIndicator: locations.length
      },] : [],
      ...osiElements.length > 0 ? [{
        Component: <PnrOsisCard data={osiElements} />,
        Title: "OSI",
        Icon: faCommentDots,
        AmountIndicator: osiElements.length
      },] : [],
      ...infos.length > 0 ? [{
        Component: <PnrInfosCard data={infos} />,
        Title: "Additional Infos",
        Icon: faInfoSquare,
        AmountIndicator: infos.length
      },] : [],
      ...contacts.length > 0 ? [{
        Component: <PnrContactsCard data={contacts} />,
        Title: "Contacts",
        Icon: faAddressBook,
        AmountIndicator: contacts.length
      },] : [],
      ...freeTextResult.length > 0 ? [{
        Component: <PnrFreeTextCard data={freeTextResult} />,
        Title: "Text",
        Icon: faText,
        AmountIndicator: undefined
      },] : [],
      ...services.length > 0 ? [{
        Component: <PnrServicesCard data={services} />,
        Title: "Services",
        Icon: faStar,
        AmountIndicator: services.length
      },] : [],
      ...ssrElements.length > 0 ? [{
        Component: <PnrSSRsCard data={ssrElements} pnrPassengers={passengers} segments={segments} />,
        Title: "SSR",
        Icon: faCommentsDollar,
        AmountIndicator: ssrElements.length
      },] : [],
      ...payments.length > 0 ? [{
        Component: <PnrResponsePaymentCard data={payments} />,
        Title: "Payments",
        Icon: faFileInvoiceDollar,
        AmountIndicator: payments.length

      },] : [],
      ...skElements.length > 0 ? [{
        Component: <PnrSksCard data={skElements} />,
        Title: "SK",
        Icon: faCopyright,
        AmountIndicator: skElements.length
      },] : [],
      ...storedFares.length > 0 ? [{
        Component: <PnrStoredFaresCard data={storedFares} />,
        Title: "Stored fares",
        Icon: faSackDollar,
        AmountIndicator: storedFares.length
      },] : [],
      ...phones.length > 0 ? [{
        Component: <PnrPhonesCard data={phones} />,
        Title: "Phones",
        Icon: faPhone,
        AmountIndicator: phones.length
      },] : [],
      ...specialDiscounts.length > 0 ? [{
        Component: <PnrSpecialDiscountsCard data={specialDiscounts} />,
        Title: "Special discounts",
        Icon: faPercentage,
        AmountIndicator: specialDiscounts.length
      },] : [],
      ...storedServices.length > 0 ? [{
        Component: <PnrStoredServicesCard data={storedServices} />,
        Title: "Stored services",
        Icon: faShoppingBasket,
        AmountIndicator: storedServices.length
      },] : [],
      ...ticketInfo.length > 0 ? [{
        Component: <PnrTicketInfoCard data={ticketInfo} />,
        Title: "Ticket infos",
        Icon: faTicket,
        AmountIndicator: ticketInfo.length
      },] : [],
      ...ticketedSevices.length > 0 ? [{
        Component: <PnrTicketedServiceCard data={ticketedSevices} />,
        Title: "Ticketed services",
        Icon: faStar,
        AmountIndicator: ticketedSevices.length
      },] : [],
      ...ticketingDates.length > 0 ? [{
        Component: <PnrTicketingDatesCard data={ticketingDates} />,
        Title: "Ticket dates",
        Icon: faCalendarDay,
        AmountIndicator: ticketingDates.length
      },] : [],
    ]
  }

  return (
    <div className="card-body">
      <TabbedContent {...TabContent} componentName="PNR"/>
    </div>
  );


}
